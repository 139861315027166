body {
  margin: 0;
}

.app-bar-link {
  color: white;
  text-decoration: none;
}

.app-bar-button {
  color: white !important;
}

.operation-preview-link {
  text-decoration: none;
}

.drawer-link {
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.drawer-link-active .MuiListItemButton-root .MuiTouchRipple-root {
  background-color: rgba(0, 0, 0, 0.05);
}

.dashboard-card {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.tabs-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiDataGrid-cellContent {
  word-break: break-word;
  white-space: normal;
}

.log-text {
  font-family: monospace !important;
}

.log-card {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.budget-cell {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.vertical-center {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.MuiDataGrid-columnHeaderTitle {
  white-space: normal !important;
  line-height: 1.35rem !important;
}